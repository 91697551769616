import React from "react"
import { Wrapper } from "./style"
import styled from "styled-components"
import { transparentize } from "polished"

export const Footer = styled(({ ...styleProps }) => {
  return (
    <footer {...styleProps}>
      <Wrapper>
        Copyright © {new Date().getFullYear()}{" "}
        <a href="https://www.donovanisherwood.com">Donovan Isherwood</a>. All
        Rights Reserved.
      </Wrapper>
    </footer>
  )
})`
  position: static;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  line-height: 2;
  text-align: center;
  height: auto;
  background-color: ${(props) =>
    transparentize(0.97, props.theme.color.foreground)};
  box-shadow: inset 0 1px 0
    ${(props) => transparentize(0.95, props.theme.color.foreground)};
`
